import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = data => {
    return (
        <Layout>
            <Seo title='404: Страница не найдена' meta={{ metaDesc: 'Страница не найдена' }} />
            <div className='global-container' style={{ textAlign: 'center' }}>
                <h1>Приплыли!</h1>
                <p>К сожалению, такой страницы нет.<br/>
                Зато вы можете вернуться на <a href='/'>главную</a> или посмотреть <a href='/menu'>меню</a>.</p>
            </div>
        </Layout>
    )
}

export default NotFoundPage
